import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/aftersun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/aftersun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-aftersun-product.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-aftersun-product-mov.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> AFTER SUN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Per rivitalizzare la pelle dopo il sole</h3>
                      <p>
                        Dopo una giornata al sole, la pelle ha bisogno di
                        rivitalizzazione ed idratazione. Regala alla tua pelle i
                        nostri prodotti doposole per ripristinare il suo
                        naturale equilibrio idrolipico. Arricchito con un
                        complesso di idratazione intensa, PIZ BUIN<sup>®</sup>{" "}
                        AFTER SUN protegge la pelle dalla disidratazione e dona
                        un’abbronzatura più bella e duratura. Tutti i prodotti
                        PIZ BUIN<sup>®</sup> Doposole contengono Vitamina E, che
                        aiuta a proteggere dall’invecchiamento precoce della
                        pelle legato all’esposizione al sole.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="latte-idratante-lenitivo-e-rinfrescante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-400ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LATTE IDRATANTE LENITIVO E RINFRESCANTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Arricchito con Aloe Vera, il nuovo PIZ BUIN
                          <sup>®</sup> AFTER SUN latte idratante lenitivo e
                          rinfrescante offre un'idratazione intensa per 24 ore e
                          riequilibra delicatamente il livello di idratazione
                          naturale della pelle affaticata dal sole aiutando a
                          prevenire l’esfoliazione per un'abbronzatura più
                          duratura. La sua formula ultra leggera, non grassa e a
                          rapido assorbimento contiene estratto di menta per un
                          effetto rinfrescante immediato e duraturo. Il nuovo
                          PIZ BUIN<sup>®</sup> AFTER SUN latte idratante
                          lenitivo e rinfrescante piace ai consumatori. Abbiamo
                          chiesto loro di dirci cosa ne pensano*.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            <strong>
                              <span className="orange">Il 100%</span>
                            </strong>{" "}
                            ha constatato:
                          </p>
                          <ul>
                            <li>Pelle immediatamente alleviata, confortata</li>
                            <li>
                              Un rinnovato equilibrio dell'idratazione naturale
                            </li>
                            <li>Pelle soffice, elastica e liscia</li>
                            <li>
                              Una consistenza non grassa, leggera e di rapido
                              assorbimento
                            </li>
                          </ul>
                          <p>
                            <strong>
                              <span className="orange">Il 95%</span>
                            </strong>{" "}
                            ha constatato:
                          </p>
                          <ul>
                            <li>Idratazione di lunga durata</li>
                            <li>Pelle immediatamente rivitalizzata</li>
                            <li>Una pelle più luminosa</li>
                            <li>Effetto rinfrescante immediato</li>
                          </ul>
                          <p className="footNote">
                            *Risultati di autovalutazione su 19 soggetti
                          </p>
                          <p className="footNote"> </p>
                          <h2>Disponibile nei formati</h2>
                          <p> 200 ml</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Leggi di piu´
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Aloe Vera
                                <div className="textBlock bottom">
                                  <p>Cos’ha di buono l'Aloe Vera?</p>
                                  <p>
                                    L'Aloe Vera è un ricco idratante naturale
                                    dalle formidabili proprietà lenitive.
                                    Contiene circa 75 sostanze attive, tra cui
                                    11 minerali, 16 aminoacidi e 8 diverse
                                    vitamine.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Estratto di Menta
                                <div className="textBlock bottom">
                                  <p>
                                    La menta è largamente utilizzata per diversi
                                    scopi cosmetici. Tra le altre cose, è nota
                                    per le sue forti proprietà naturali
                                    rinfrescanti.
                                  </p>
                                  <p>
                                    <em>
                                      Trovi l'estratto di menta in PIZ BUIN
                                      <sup>®</sup> After Sun Latte Idratante
                                      Lenitivo e Rinfrescante
                                    </em>
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fdf3033b2f5f4b9f6c2f8a54acf02d47_f45.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="loci-n-hidratante-intensificadora-del-bronceado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        PIZ BUIN® Latte Doposole Intensificatore di Abbronzatura
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICI</h4>
                        <p>
                          Offre 24 ore di idratazione:
                          <br />
                          Aiuta a ristabilire il naturale equilibrio
                          idrolipidico della pelle disidratata dal sole.
                          <br />
                          Aiuta a prevenire la desquamazione per un’abbronzatura
                          più duratura.
                          <br />
                          Ad assorbimento rapido. Non unge.
                          <br />
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Con Tanimel, un estratto vegetale che
                                    intensifica l’abbronzatura e aiuta a
                                    stimolare la produzione dei pigmenti
                                    naturali della pelle responsabili
                                    dell’abbronzatura*.
                                    <br />
                                    <span className="footNote">
                                      *Test in vitro
                                    </span>
                                  </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="instant-mist-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>PIZ BUIN® After Sun Spray Sollievo Immediato</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICI</h4>
                        <p>
                          Offre 48 ore di idratazione:
                          <br />
                          Aiuta a ristabilire il naturale equilibrio
                          idrolipidico della pelle disidratata dal sole
                          <br />
                          Aiuta a prevenire la desquamazione per un’abbronzatura
                          più duratura
                          <br />
                          Formula ultraleggera
                          <br />
                          Assorbimento rapido. Non appiccica e non unge
                          <br />
                          Rinfresca e lenisce la pelle
                          <br />
                        </p>
                        <div className="products-page__product__details__directions">
                          <h2>MODO D’USO</h2>
                          <p>
                            Per 48 ore di idratazione, applicare di nuovo dopo
                            la doccia o il bagno dopo essersi asciugati con
                            l’asciugamano.
                          </p>
                          <p>
                            Precauzioni: Non spruzzare vicino a viso o occhi.
                            Fare attenzione, il pavimento può diventare
                            scivoloso durante l’uso. Evitare l’inalazione. Usare
                            solo in ambienti ben ventilati.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>Con Acido Ialuronico e Glicerina</p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/it/prodotti/aftersun/"
                      target="_self"
                    >
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
